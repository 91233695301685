<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <v-card style="max-height: 80vh; display: flex; flex-direction: column;">
      <v-card-title class="headline lighten-2 d-flex flex-column pa-0">
        <div class="d-flex flex-row event-title">
          Create an event
          <v-btn icon @click="onCloseDialog"><v-icon>mdi-close</v-icon></v-btn>
        </div>
        <v-divider style="width: 100%;" />
      </v-card-title>
      <div class="dialog-content">
        <v-card-text class="d-flex flex-row" style="align-items: center">
          <editable-avatar
            :image="profile.thumbnail ? profile.thumbnail : profile.image"
            :editable="false"
            :size="50"
          />
          <span
            style="font-size: 20px; font-familay: 'Poppins-Medium'; color: #5b2cae; padding-left: 15px;"
          >
            {{
              profile.name
                ? profile.name
                : profile.first_name + " " + profile.last_name
            }}
          </span>
        </v-card-text>
        <v-card-text>
          <v-textarea
            rows="4"
            auto-grow
            label="What do you want to talk about?"
            solo
            flat
            v-model="text"
          />
        </v-card-text>
        <v-hover v-slot="{ hover }" v-if="selectedFile">
          <div class="attachment px-5 pb-5">
            <v-img
              :src="selectedImage"
              alt="Image"
              class="ma-5"
              v-if="type == 'image'"
            />
            <vue-plyr
              ref="plyr"
              :options="{
                autoplay: false,
                muted: true,
              }"
              v-else-if="type == 'video'"
            >
              <video controls crossorigin playsinline>
                <source :src="selectedVideo" type="video/mp4" />
              </video>
            </vue-plyr>

            <v-btn
              @click="onRemoveAttachment"
              class="remove_button"
              fab
              x-small
              v-show="hover"
            >
              <v-icon v-text="'mdi-close'" size="16" />
            </v-btn>
          </div>
        </v-hover>
        <input
          ref="uploader"
          class="d-none"
          type="file"
          :value="file"
          :accept="type + '/*'"
          @change="onFileChanged"
        />
      </div>
      <v-card-actions>
        <v-btn text @click="onImagePick"
          ><div>
            <v-img
              :src="require('@/assets/svg/create-image-event.svg')"
              width="30"
              height="20"
              contain
            /></div
        ></v-btn>
        <v-btn text @click="onVideoPick"
          ><div>
            <v-img
              :src="require('@/assets/svg/create-video-event.svg')"
              width="30"
              height="20"
              contain
            /></div
        ></v-btn>
        <!-- <v-btn icon><v-icon>mdi-calendar-star</v-icon></v-btn> -->
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          rounded
          @click="onPost"
          :loading="loading"
          :disabled="!text && !type"
        >
          Post
        </v-btn>
      </v-card-actions>
      <v-snackbar v-model="snackbar">
        {{ errorMessage }}
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from "vuex";
import EditableAvatar from "@/components/EditableAvatar.vue";

export default {
  components: { EditableAvatar },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    onCloseDialog: {
      type: Function,
    },
    initalType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      type: "",
      selectedFile: null,
      selectedImage: null,
      selectedVideo: null,
      file: null,
      text: "",
      errorMessage: null,
      snackbar: false,
    };
  },
  methods: {
    ...mapActions("event", {
      addEvent: "addEvent",
    }),
    onImagePick() {
      this.onRemoveAttachment();
      this.type = "image";
      setTimeout(() => {
        window.addEventListener("focus", () => {}, { once: true });
        this.$refs.uploader.click();
      }, 300);
    },
    onVideoPick() {
      this.onRemoveAttachment();
      this.type = "video";
      setTimeout(() => {
        window.addEventListener("focus", () => {}, { once: true });
        this.$refs.uploader.click();
      }, 300);
    },
    onFileChanged(e) {
      if (e.target.files.length != 0) {
        this.selectedFile = e.target.files[0];
        if (this.type == "image") {
          this.selectedImage = URL.createObjectURL(this.selectedFile);
        } else {
          this.selectedVideo = URL.createObjectURL(this.selectedFile);
        }
      }
    },
    onRemoveAttachment() {
      if (this.type == "video") {
        setTimeout(() => {
          this.selectedFile = null;
          this.selectedImage = null;
          this.type = "";
        }, 200);
      } else {
        this.selectedFile = null;
        this.selectedImage = null;
        this.type = "";
      }
    },
    onPost() {
      this.loading = true;
      var formData = new FormData();
      if (this.selectedFile) {
        formData.append("file", this.selectedFile);
      }
      if (this.type) formData.append("type", this.type);
      if (this.text) formData.append("text", this.text.trim());

      this.addEvent(formData)
        .then(() => {
          this.loading = false;
          this.onRemoveAttachment();
          this.text = "";
          this.onCloseDialog();
        })
        .catch((error) => {
          this.loading = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });
    },
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
    }),
  },
  watch: {
    initalType(newValue) {
      if (newValue == "image") {
        this.onImagePick();
      } else if (newValue == "video") {
        this.onVideoPick();
      }
    },
  },
};
</script>
<style scoped>
.attachment {
  overflow: visible;
  position: relative;
  cursor: pointer;
}
.remove_button {
  position: absolute;
  top: 10px;
  right: 30px;
  z-index: 10;
}
.event-title {
  justify-content: space-between;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 10px;
  padding-right: 24px;
  padding-left: 24px;
}
div >>> .v-dialog {
  max-height: 80%;
}
.dialog-content {
  flex-grow: 1;
  overflow-y: auto;
}
div >>> ::-webkit-scrollbar {
  width: 5px;
}

/* Track */
div >>> ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
div >>> ::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
div >>> ::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
</style>
