<template>
  <v-card class="ma-5" v-if="profile" style="width: 100%">
    <v-card-title class="d-flex flex-row">
      <editable-avatar
        :image="profile.thumbnail ? profile.thumbnail : profile.image"
        :editable="false"
        :size="$vuetify.breakpoint.xs ? 40 : 50"
      />
      <v-btn
        v-text="'Start an event'"
        rounded
        class="text-none mx-4"
        outlined
        large
        style="flex-grow:1; justify-content: flex-start"
        color="#c1c1c1"
        @click="showDialog('')"
      />
    </v-card-title>
    <v-card-text>
      <v-btn
        text
        color="primary"
        @click="showDialog('image')"
        :small="$vuetify.breakpoint.xs"
      >
        <div class="mr-3">
          <v-img
            :src="require('@/assets/svg/create-image-event.svg')"
            :width="$vuetify.breakpoint.xs ? 20 : 30"
            height="20"
            contain
          />
        </div>
        Image
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="showDialog('video')"
        :small="$vuetify.breakpoint.xs"
      >
        <div class="mr-3">
          <v-img
            :src="require('@/assets/svg/create-video-event.svg')"
            :width="$vuetify.breakpoint.xs ? 20 : 30"
            height="20"
            contain
          />
        </div>
        Video
      </v-btn>
      <!-- <v-btn text color="primary">
        <v-icon class="mr-2">mdi-calendar-star</v-icon>Special Event
      </v-btn> -->
    </v-card-text>
    <create-event-dialog
      :dialog="dialog"
      :onCloseDialog="onCloseDialog"
      :initalType="type"
    />
  </v-card>
</template>
<script>
import { mapState } from "vuex";
import EditableAvatar from "@/components/EditableAvatar.vue";
import CreateEventDialog from "./CreateEventDialog.vue";
export default {
  components: { EditableAvatar, CreateEventDialog },
  data() {
    return {
      loading: false,
      dialog: false,
      type: "",
    };
  },
  methods: {
    showDialog(type) {
      this.dialog = true;
      this.type = type;
    },
    onCloseDialog() {
      this.dialog = false;
    },
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
    }),
  },
};
</script>
